import React, { useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"

import TextAreaEmoticons from "./components/textareaAddEmotes"
import ScrollEmoticonsList from "./components/emoticonsReactWindowScroll"
import {
  Title,
  Dropdown,
  Icon,
  Button,
  ArrowDown,
} from "../../StyleComponents/styles"
import Popup from "./components/Popup"
import { isSSR } from "../../components/Script/script"

/* import can be lazy load  */
const DropDownList = React.lazy(() => import("./components/dropDownComponent"))
const Pagination = React.lazy(() =>
  import("../../components/Pagination/Pagination")
)

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "✂ Emoticons", link: "/emoticons-copy-and-paste/" },
]
const seeAlsoArray = [
  "/emoji-copy-and-paste",
  "/font-generator",
  "/html-entity",
  "/text-art",
]

function EmoticonsCopy(props) {
  const { currentPage, totalPages, pageItems, allPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/emoticons-copy-and-paste/"
      : `/emoticons-copy-and-paste/${(currentPage - 1).toString()}/`
  const nextPage = `/emoticons-copy-and-paste/${(currentPage + 1).toString()}/`

  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)

  let [valueInput, setValueInput] = useState(() => {
    return typeof window !== "undefined"
      ? localStorage.getItem("Emoticons") || ""
      : ""
  })

  const mycopy = e => {
    let emoji = e.target.value
    setEmoji(emoji)
    setShow(true)
  }

  function addTOlistFunction() {
    setValueInput(text => text + emoji)
    setShow(false)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="(̿▀̿ ̿Ĺ̯̿̿▀̿ ̿)̄ ✂️Copy 📋Paste Emoticons ✌(-‿-)✌"
        description="Emoticons ✂️ Copy 📋Paste  ✌(-‿-)✌ - °◦=͟͟͞͞ʕ̡̢̡ु•̫͡•ʔ̡̢̡ु ☏ Simple beautiful Copy Paste Emoticons text Symbols [̲̅$̲̅(̲̅ ͡° ͜ʖ ͡°̲̅)̲̅$̲̅], ~(⁰▿⁰)~ Danse Emoticons, Large Emoticons, ❤ Love Emoticons, ☁ Clouds Emoticons ,WTF Emoticons ༎ຶ‿༎ຶ, ( ื▿ ืʃƪ)  smiley Emoticons ."
        keywords={[
          "emote,emoticons, copy paste emoticons, ascii Symbols,Danse Emoticons, Large Emoticons,Love Emoticons,WTF Emoticons,smiley Emoticons ",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title id="listOfEmoji">Emoticons Copy And Paste ψ(｀∇´)ψ</Title>

        {show && (
          <Popup
            emoji={emoji}
            addToList={addTOlistFunction}
            close={() => setShow(false)}
          />
        )}
        <div>
          <TextAreaEmoticons
            valueInput={valueInput}
            setValueInput={setValueInput}
          />
          <br />
          <Dropdown className="mb-4">
            <Button aria-haspopup="true" aria-controls="dropdown-menu">
              Emoticons Category
              <Icon>
                <ArrowDown />
              </Icon>
            </Button>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <DropDownList list={allPages} />
              </React.Suspense>
            )}
          </Dropdown>
        </div>

        <ScrollEmoticonsList pageItems={pageItems} mycopy={mycopy} />
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              isFirst={isFirst}
              isLast={isLast}
              prevPage={prevPage}
              nextPage={nextPage}
              pagesLink={`emoticons-copy-and-paste`}
            />
          </React.Suspense>
        )}
        <br />
        <p>
          We help you to find cute fancy emoticons, just click on an emoticon to
          copy it to the clipboard and paste them wherever you want, such as on
          Twitter, Facebook, Instagram etcetera. Also, write your text above in
          the text area and add emoticons to it, just click on the emoticons you
          want to add it and click the button "Add to Text Area".
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmoticonsCopy
